.accordion {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.accordionItem {
  border: none;
  border-bottom: 1px solid #E5E7EB;
  padding-bottom: 15px;
  scroll-margin-top: 150px;

  &:last-child {
    border-bottom: none;
  }
}

.accordionTrigger {
  padding: 0;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

.accordionIcon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #9ca3af;
}

.accordionIconMinus {
  color: #D9D9D9;
}

.accordionIconPlus {
  color: #151B26;
}

.accordionContent {
  padding-top: 16px;
  padding-bottom: 24px;
}


